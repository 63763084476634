import React from 'react'
import { Redirect } from '@reach/router'
import { isZhCN } from '~src/utils/locale'

class FeaturesIndex extends React.Component {
  render () {
    return isZhCN() ? (
      <Redirect noThrow to="/features/notes-zh" />
    ) : (
      <Redirect noThrow to="/features/notes" />
    )
  }
}

export default FeaturesIndex
